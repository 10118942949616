import React from 'react';
import { BsInstagram, BsArrowLeftShort, BsArrowRightShort } from 'react-icons/bs';

import { SubHeading } from '../../components';
import { images } from '../../constants';
import './Gallery.css';

const galleryImages = [
  { id: 'BIGQ1kCgDD7', src: images.cut1 },
  { id: 'BGE2bsFi42L', src: images.cut3 },
  { id: 'BpuMl78n7vG', src: images.cut5 },
  { id: 'BFogWZei40A', src: images.cut6 },
  { id: 'BIGRIxIAukK', src: images.cut2 },
];

const Gallery = () => {
  const scrollRef = React.useRef(null);

  const scroll = (direction, id) => {
    const { current } = scrollRef;
  
    const index = galleryImages.findIndex((image) => image.id === id);
    if (index !== -1) {
      const containerWidth = current.offsetWidth;
      const cardWidth = current.children[0].offsetWidth;
      const cardsInView = Math.floor(containerWidth / cardWidth);
  
      let scrollAmount;
      if (direction === "left") {
        scrollAmount = Math.max(current.scrollLeft - cardWidth, 0);
      } else {
        scrollAmount = Math.min(current.scrollLeft + cardWidth, 
        (galleryImages.length - cardsInView) * cardWidth);
      }
      current.scrollTo({ left: scrollAmount, behavior: "smooth" });
    }
  };
  
  

  const handleViewMoreClick = () => {
    window.open('https://www.instagram.com/leftcoastcuts/', '_blank');
  };

  const handleImageClick = (postId) => {
    window.open(`https://www.instagram.com/p/${postId}/`, '_blank');
  };

  return (
    <div className='app__gallery flex__center' id='gallery'>
      <div className='app__gallery-content'>
        <SubHeading title='Instagram' />
        <h1 className='headtext__cormorant'>Photo Gallery</h1>
        <p className='p__opensans' style={{ color: '#AAA', marginTop: '2rem' }}>
          See what&apos;s happening at Left Coast Cuts
        </p>
        <button type='button' className='custom__button' onClick={handleViewMoreClick}>
          View more
        </button>
      </div>

      <div className='app__gallery-images'>
        <div className='app__gallery-images_container' ref={scrollRef}>
          {galleryImages.map((image, index) => (
            <div
              className='app__gallery-images_card flex__center'
              key={`gallery_image-${index + 1}`}
              onClick={() => handleImageClick(image.id)}
            >
              <img src={image.src} alt='gallery' />
              <BsInstagram className='gallery__image-icon' />
            </div>
          ))}
        </div>
        <div className='app__gallery-images_arrows'>
          <BsArrowLeftShort className='gallery__arrow-icon' onClick={() => scroll('left', galleryImages[0].id)} />
          <BsArrowRightShort className='gallery__arrow-icon' onClick={() => scroll('right', galleryImages[0].id)} />
        </div>
      </div>
    </div>
  );
};

export default Gallery;
