import React, { useState } from 'react';
import { GiHamburgerMenu } from 'react-icons/gi';
import { ImScissors } from 'react-icons/im';


import images from '../../constants/images';
import './Navbar.css';

const Navbar = () => {
  const [toggleMenu, setToggleMenu] = useState(false);
  return(
    <nav className = "app__navbar">
      <div className = "app__navbar-logo">
        <img src={images.LeftCoastCuts_Web} alt="app logo" />
      </div>
      <ul className = "app__navbar-links">
        <li className = "p__opensans"><a href="#home">Home</a></li>
        <li className = "p__opensans"><a href="#about">About</a></li>
        <li className = "p__opensans"><a href="#prices">Prices</a></li>
        <li className = "p__opensans"><a href="#gallery">Gallery</a></li>
        <li className = "p__opensans"><a href="#contact">Contact</a></li>
      </ul>
      <div className = "app__navbar-login">
        <a href="#login" className="p__opensans">Log In / Register</a>
        <div />
        <a href="/" className="p__opensans">Book Appointment</a>
      </div>
      <div className="app__navbar-smallscreen">
        <GiHamburgerMenu color="#fff" fontSize={27} onClick={() => setToggleMenu(true)}/>
        
       {toggleMenu && (
        <div className="app__navbar-smallscreen_overlay flex__center slide-bottom">
          <ImScissors fontSize={27} className="overlay__close" onClick={() => setToggleMenu(false)}/>
          <ul className = "app__navbar-smallscreen-links">
          <li className="p__opensans"><a href="#home" onClick={() => setToggleMenu(false)}>Home</a></li>
            <li className="p__opensans"><a href="#about" onClick={() => setToggleMenu(false)}>About</a></li>
            <li className="p__opensans"><a href="#prices" onClick={() => setToggleMenu(false)}>Prices</a></li>
            <li className="p__opensans"><a href="#gallery" onClick={() => setToggleMenu(false)}>Gallery</a></li>
            <li className="p__opensans"><a href="#contact" onClick={() => setToggleMenu(false)}>Contact</a></li>
            <img src={images.LeftCoastCuts_BarberPole} alt="app logo" />
          </ul>
        </div>
       )}

      </div>
    </nav>
  )
}

export default Navbar;
