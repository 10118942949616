
const haircut = [
  {
    title: 'Haircut',
    price: '$30',
    tags: 'The classic haircut tailored to your desire',
  },
  {
    title: 'Haircut & Shave',
    price: '$40',
    tags: 'Tailored haircut finished off with a Hot towel shave',
  },
  {
    title: 'Hot Towel Shave',
    price: '$15',
    tags: 'Includes hot towel, hot lather, steam exfoliator, and face massage',
  },
  {
    title: 'Beard & Mustach Trim',
    price: '$10',
    tags: 'Facial hair trim with a straight razor line up',
  },
];

const special = [
  {
    title: 'Military',
    price: '$25',
    tags: 'Discount for all active duty military',
  },
  {
    title: 'Seniors',
    price: '$20',
    tags: '65+ senior discount',
  },
  {
    title: 'Edge up',
    price: '$10',
    tags: ' Sharp hairline edges for a polished look',
  },

  {
    title: 'Designs & Color',
    price: '$45+',
    tags: 'Pricing depend on complexity of design and coloring',
  },
];


export default { haircut, special };
