import React from 'react';

// import { images } from '../../constants';
import { leftcoast_webvid } from '../../constants';
import './Header.css';

const Header = () => (
  <div className="app__header" id="home">
    <div className="app__header-vid">
    <div className='app__header-overlay'></div>
      <video src={leftcoast_webvid} type="video/mov" muted autoPlay loop playsinline></video>
      <script>
        document.getElementById('leftcoast_webvid').play();
      </script>
    </div>
    <div className="app__wrapper_info">
      <h1 className="app__header-h1"> For that razor sharp look</h1>
      <button type="button" className="custom__button1">Make an Appointment</button>
    </div>
    {/* <div className="app__wrapper_img">
      <img className='cut1' src={images.leftcoastshop_img} alt="cut1"/>
    </div> */}
  </div>
);


export default Header;
