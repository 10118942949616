import bg from '../assets/bg.png';
import overlaybg from '../assets/overlaybg.png';
import welcome from '../assets/welcome.png';
import findus from '../assets/findus.png';
import laurels from '../assets/laurels.png';
import LeftCoastCuts_Web from '../assets/LeftCoastCuts_Web.png';
import LeftCoastCuts_Razor from '../assets/LeftCoastCuts_Razor.png';
import LeftCoastCuts_BarberPole from '../assets/LeftCoastCuts_BarberPole.png';
import leftcoastshop_img1 from '../assets/leftcoastshop_img1.jpg';
import leftcoastshop_img from '../assets/leftcoastshop_img.png';
import barberchair from '../assets/barberchair.png';
import cut1 from '../assets/cut1.jpg';
import cut2 from '../assets/cut2.jpg';
import cut3 from '../assets/cut3.jpg';
import cut4 from '../assets/cut4.jpg';
import cut5 from '../assets/cut5.jpg';
import cut6 from '../assets/cut6.jpg';
import scissors from '../assets/scissors.jpg';
import scissorsNew from '../assets/scissorsNew.png';
import clippers from '../assets/clippers.png';


export default {
  bg,
  overlaybg,
  welcome,
  findus,
  laurels,
  LeftCoastCuts_Web,
  LeftCoastCuts_Razor,
  LeftCoastCuts_BarberPole,
  leftcoastshop_img,
  leftcoastshop_img1,
  cut1,
  cut2,
  cut3,
  cut4,
  cut5,
  cut6,
  scissors,
  scissorsNew,
  clippers,
  barberchair,
};
