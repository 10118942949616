import React from 'react';
import { SubHeading, MenuItem } from '../../components';
import { images, data } from '../../constants';
import './Prices.css';

const Prices = () => (
  <div className='app__prices flex__center section__padding' id='prices'>
    <div className='app__prices-title'>
      <SubHeading title="Look and Feel Your Best" />
      <h1 className='headtext__cormorant'>Prices</h1>
    </div>
    <div className='app__prices-menu'>
      <div className='app__prices-menu_haircuts flex__center'>
        <p className='app__prices-menu_heading'>Haircuts & Beard</p>
        <div className='app__prices_menu_items'>
          {data.haircut.map((haircut, index) => (
            <MenuItem key={haircut.title + index} title={haircut.title} price={haircut.price} tags={haircut.tags} />
          ))}
        </div>
      </div>
      <div className='app__prices-menu_img'>
            <img src={images.clippers} alt="menu img" />
      </div>
      <div className='app__prices-menu_specials flex__center'>
        <p className='app__prices-menu_heading'>Special</p>
        <div className='app__prices_items'>
          {data.special.map((special, index) => (
            <MenuItem key={special.title + index} title={special.title} price={special.price} tags={special.tags} />
          ))}
        </div>
      </div>
    </div>
    <div>
      <button type='button' className='custom__button'>View Prices</button>
    </div>
  </div>
);

export default Prices;
