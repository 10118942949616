import React from 'react';
import { SubHeading } from '../../components';
import { images } from '../../constants';

const FindUs = () => {
  const handleVisitUsClick = () => {
    window.open('https://www.google.com/maps/place/Left+Coast+Cuts/@32.7423883,-117.0236582,15z/data=!4m6!3m5!1s0x80d9512b5dca5511:0x364ee6da4db4a5f9!8m2!3d32.7423883!4d-117.0236582!16s%2Fg%2F11b7c6mtrh', '_blank');
  };

  return (
    <div className='app__bg app__wrapper section__padding' id='contact'>
      <div className='app__wrapper_info'>
        <SubHeading title='Contact' />
        <h1 className='headtext__cormorant' style={{ marginBottom: '3rem' }}> Find Us</h1>
        <div className='app__wrapper-content'>
          <p className='p__opensans'>8087 Broadway Lemon Grove, Ca</p>
          <p className='p__opensans'>619-551-5531</p>
          <p className='p__opensans'>info@leftcoastcuts.com</p>
          <p className='p__cormorant' style={{ color: '#DCCA87', margin: '2rem 0' }}>Opening Hours</p>
          <p className='p__opensans'>Tues - Sat: 9:00 am - 6:00 pm </p>
          <p className='p__opensans'>Sun - Mon: 10:00 am - 5:00 pm</p>
        </div>
        <button className='custom__button' style={{ marginTop: '2rem' }} onClick={handleVisitUsClick}>Visit Us</button>
      </div>

      <div className='app__wrapper_img'>
        <img src={images.barberchair} alt='findus' />
      </div>
    </div>
  );
};

export default FindUs;
