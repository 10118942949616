import React from 'react';
import {images} from '../../constants';

import './AboutUs.css';

const AboutUs = () => (
  <div className='app__aboutus app__bg flex__center section__padding' id='about'>
    <div className='app__aboutus-overlay flex__center'>
    </div>

    <div className='app__aboutus-content flex__center'>
      <div className='app__aboutus-content_about'>
        <h1 className='headtext__cormorant'>About Us</h1>
        <p className='p__opensans' style={{ padding: '0 1rem' }}>At Left Coast Cuts, we are a team of experienced barbers dedicated to providing high-quality haircuts and grooming services to our clients. We are passionate about our craft and strive to create a welcoming atmosphere where every customer feels comfortable and relaxed.</p>
        <button type='button' className='custom__button'> Learn more</button>
      </div>
      <div className='app__aboutus-content_barberpole flex__center'>
        <img src={images.scissorsNew} alt="barberpole" />
      </div>
      <div className='app__aboutus-content_history'>
        <h1 className='headtext__cormorant'>Our History</h1>
        <p className='p__opensans' style={{ padding: '0 1rem' }}> In 2008, Left Coast Cuts was founded by owner Josh Rojas in Lemon Grove, CA. Since then, Left Coast Cuts has become a go-to destination for locals and professional athletes in San Diego. With a commitment to providing high-quality haircuts and excellent customer service, Left Coast Cuts has built a loyal following over the years.</p>
        <button type='button' className='custom__button'> History</button>
      </div>
    </div>
  </div>
);

export default AboutUs;
